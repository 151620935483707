body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Roboto, sans-serif;
}
#header,
#topbar {
    background: 0 0;
    transition: 0.5s;
    color: #4f6a7d;
}
#header,
#topbar,
.counts .count-box a:hover,
.departments .nav-link.active h4,
.departments .nav-link:hover h4,
.doctors .member .social a:hover,
.faq .faq-list a.collapse,
.faq .faq-list a.collapsed:hover,
.services .title a:hover,
a {
    color: #4f6a7d;
    text-decoration: none;
}
#intro,
.nav-menu a,
.nav-menu > ul > li {
    position: relative;
}
.mobile-nav,
.nav-menu .drop-down ul {
    left: 15px;
    visibility: hidden;
    opacity: 0;
}
#hero,
#intro {
    height: 100vh;
    width: 100%;
}
#header .logo,
.nav-menu a,
.section-title h2,
.style-card-header {
    text-transform: uppercase;
    text-transform: uppercase;
}
body {
    color: #444;
}
a:hover {
    color: #fa6742;
    text-decoration: none;
}
#intro {
    background: url(./assets/Images/test/Hmepage.jpg) center top/cover no-repeat #f5f8fd;
}
#intro .intro-info h1 {
    margin-top: 150px;
    vertical-align: center;
    color: #4f6a7d;
    margin-bottom: 40px;
    font-size: 48px;
    font-weight: 700;
}
#categories-reports-title a:hover,
#footer .footer-top .footer-links ul a:hover,
#intro .intro-info h1 span,
.categories-indutries a:hover,
.footer-credits:hover,
.nav-menu .active > a,
.nav-menu .drop-down ul .active > a,
.nav-menu .drop-down ul a:hover,
.nav-menu .drop-down ul li:hover > a,
.nav-menu a:hover,
.nav-menu li:hover > a,
.section-title h2 span {
    color: #fa6742;
}
#intro .btn {
    font-family: Montserrat, sans-serif;
    background: #fa6742;
    color: #fff;
    transition: 0.5s ease-in-out;
}
#intro .btn:hover {
    background: 0 0;
    color: #fa6742;
    border: 1px solid #fa6742;
}
#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #4f6a7d;
    border-top-color: #ecf8f9;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: 1s linear infinite animate-preloader;
    animation: 1s linear infinite animate-preloader;
}
@-webkit-keyframes animate-preloader {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes animate-preloader {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}
::-webkit-scrollbar-thumb {
    background: #4f6a7d;
    border-radius: 10px;
}
.appointment-btn,
.back-to-top i {
    background: #4f6a7d;
    color: #fff;
    border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
    background: #fa6742;
}
.back-to-top {
    position: fixed;
    display: none;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
}
.back-to-top i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 40px;
    height: 40px;
    transition: 0.4s;
}
.appointment-btn:hover,
.back-to-top i:hover {
    background: #fa6742;
    color: #fff;
}
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}
#topbar {
    height: 40px;
    font-size: 16px;
    font-weight: 600;
}
#topbar.topbar-scrolled {
    top: -40px;
}
#topbar i {
    padding-right: 6px;
}
#header {
    z-index: 997;
    padding: 20px 0;
    top: 40px;
}
#header.header-scrolled {
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid #4f6a7d;
}
#header .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.5px;
}
#hero .btn-get-started,
.cta .cta-btn {
    letter-spacing: 1px;
    font-family: Roboto, sans-serif;
}
#header .logo a,
.mobile-nav-toggle i {
    color: #555;
}
#header .logo img {
    max-height: 60px;
    width: 60%;
}
.mobile-nav *,
.nav-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.nav-menu > ul {
    display: flex;
}
.nav-menu > ul > li {
    white-space: nowrap;
    padding: 10px 0 10px 28px;
}
.nav-menu a {
    display: block;
    color: #4f6a7d;
    transition: 0.3s;
    font-size: 15px;
    font-family: Roboto, sans-serif;
}
.nav-menu .drop-down ul {
    display: block;
    position: absolute;
    top: calc(100% + 30px);
    z-index: 99;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}
.nav-menu .drop-down:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}
.nav-menu .drop-down li {
    min-width: 180px;
    position: relative;
}
.nav-menu .drop-down ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #194b4d;
}
.nav-menu .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 5px;
}
.nav-menu .drop-down .drop-down ul {
    top: 0;
    left: calc(100% - 30px);
}
.nav-menu .drop-down .drop-down:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
}
.mobile-nav .drop-down > a,
.nav-menu .drop-down .drop-down > a {
    padding-right: 35px;
}
.nav-menu .drop-down .drop-down > a:after {
    content: "\eaa0";
    font-family: IcoFont;
    position: absolute;
    right: 15px;
}
@media (max-width: 1366px) {
    .nav-menu .drop-down .drop-down ul {
        left: -90%;
    }
    .nav-menu .drop-down .drop-down:hover > ul {
        left: -100%;
    }
    .nav-menu .drop-down .drop-down > a:after {
        content: "\ea9d";
    }
}
.appointment-btn {
    margin-left: 25px;
    padding: 8px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
}
.mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 20px;
    z-index: 9998;
    border: 0;
    background: 0 0;
    font-size: 24px;
    transition: 0.4s;
    outline: 0 !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
}
.mobile-nav,
.mobile-nav-overly {
    position: fixed;
    transition: 0.2s ease-in-out;
}
.mobile-nav {
    top: 55px;
    right: 15px;
    bottom: 15px;
    z-index: 9999;
    overflow-y: auto;
    background: #fff;
    border-radius: 10px;
    padding: 10px 0;
}
#hero .container,
.section-title {
    padding-bottom: 30px;
    text-align: center;
}
.mobile-nav a {
    display: block;
    position: relative;
    color: #555;
    padding: 10px 20px;
    font-weight: 500;
    outline: 0;
}
#hero h2,
.section-title h2 {
    font-weight: 700;
    margin-bottom: 20px;
}
.mobile-nav .active > a,
.mobile-nav a:hover,
.mobile-nav li:hover > a {
    color: #4f6a7d;
    text-decoration: none;
}
.mobile-nav .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 10px;
    position: absolute;
    right: 15px;
}
.mobile-nav .active.drop-down > a:after {
    content: "\eaa1";
}
.mobile-nav .drop-down ul {
    display: none;
    overflow: hidden;
}
.mobile-nav .drop-down li {
    padding-left: 20px;
}
.mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 9997;
    top: 0;
    left: 0;
    background: rgba(60, 60, 60, 0.6);
    overflow: hidden;
    display: none;
}
.gallery,
.mobile-nav-active {
    overflow: hidden;
}
.mobile-nav-active .mobile-nav {
    opacity: 1;
    visibility: visible;
}
.featured-services .icon-box:hover .description,
.featured-services .icon-box:hover .icon i,
.featured-services .icon-box:hover .title a,
.footer-credits,
.mobile-nav-active .mobile-nav-toggle i {
    color: #fff;
}
#hero {
    background-color: rgba(60, 60, 60, 0.8);
    overflow: hidden;
    position: relative;
}
#hero .carousel,
#hero .carousel-inner,
#hero .carousel-item,
#hero .carousel-item::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
#hero .carousel-item {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
#hero .container {
    background: rgba(255, 255, 255, 0.9);
    padding-top: 30px;
    margin-bottom: 50px;
    border-top: 4px solid #4f6a7d;
}
@media (max-width: 1200px) {
    #hero .container {
        margin-left: 50px;
        margin-right: 50px;
    }
}
#hero h2 {
    color: #2f2f2f;
    font-size: 36px;
}
#hero p {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    margin: 0 auto 30px;
    color: #555;
}
#hero .carousel-inner .carousel-item {
    transition-property: opacity;
    background-position: center top;
}
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right,
#hero .carousel-inner .carousel-item {
    opacity: 0;
}
#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-left,
#hero .carousel-inner .carousel-item-prev.carousel-item-right {
    opacity: 1;
    transition: 0.5s;
}
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right,
#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev {
    left: 0;
    transform: translate3d(0, 0, 0);
}
#hero .carousel-control-next,
#hero .carousel-control-prev {
    width: 10%;
    top: 112px;
}
#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
    background: rgba(63, 187, 192, 0.4);
    font-size: 36px;
    line-height: 1;
    width: auto;
    height: auto;
    border-radius: 50px;
    padding: 10px;
    transition: 0.3s;
    color: rgba(255, 255, 255, 0.5);
}
#hero .carousel-control-next-icon:hover,
#hero .carousel-control-prev-icon:hover {
    background: rgba(63, 187, 192, 0.6);
    color: rgba(255, 255, 255, 0.8);
}
#hero .carousel-indicators li {
    cursor: pointer;
    background: #fff;
    overflow: hidden;
    border: 0;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    opacity: 0.6;
    transition: 0.3s;
}
#hero .carousel-indicators li.active {
    opacity: 1;
    background: #4f6a7d;
}
#hero .btn-get-started {
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    padding: 14px 32px;
    border-radius: 4px;
    transition: 0.5s;
    line-height: 1;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: #4f6a7d;
}
#footer .footer-top .footer-newsletter form input[type="submit"]:hover,
#hero .btn-get-started:hover,
.contact .php-email-form button[type="submit"]:hover,
.pricing .btn-buy:hover {
    background: #fa6742;
}
@media (max-width: 992px) {
    #header {
        padding: 15px 0;
        top: 0;
        background-color: #fff;
    }
    #header .logo {
        font-size: 28px;
    }
    #hero .carousel-control-next,
    #hero .carousel-control-prev {
        top: 66px;
    }
    #hero {
        height: 100vh;
    }
    #hero .container {
        margin-top: 100px;
    }
}
@media (min-width: 1024px) {
    #hero .carousel-control-next,
    #hero .carousel-control-prev {
        width: 5%;
    }
}
@media (max-height: 500px) {
    #hero {
        height: 160vh;
    }
}
section {
    padding: 40px 0 30px;
    overflow: hidden;
}
.section-bg {
    background-color: #f7fcfc;
}
.section-title h2 {
    font-size: 32px;
    padding-bottom: 20px;
    position: relative;
}
.about .content ul,
.breadcrumbs ol {
    padding: 0;
    list-style: none;
}
.section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #4f6a7d;
    bottom: 2;
    left: calc(50% - 25px);
}
.about .content p:last-child,
.departments .tab-pane p:last-child,
.section-title p {
    margin-bottom: 0;
}
.breadcrumbs {
    padding: 20px 0;
    background-color: #ecf8f9;
    min-height: 40px;
    margin-top: 120px;
}
.cta,
.faq {
    padding: 60px 0;
}
.breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
    margin: 0;
}
@media (max-width: 992px) {
    .breadcrumbs {
        margin-top: 70px;
    }
    .breadcrumbs h2 {
        margin: 0 0 10px;
    }
}
.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    font-size: 14px;
}
.breadcrumbs ol li + li {
    padding-left: 10px;
}
.breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
}
.featured-services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
    transition: 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
}
.featured-services .icon-box::before {
    content: "";
    position: absolute;
    background: #d9f1f2;
    right: 0;
    left: 0;
    bottom: 0;
    top: 100%;
    transition: 0.3s;
    z-index: -1;
}
.featured-services .icon-box:hover::before {
    background: #4f6a7d;
    top: 0;
    border-radius: 0;
}
.featured-services .icon {
    margin-bottom: 15px;
}
.featured-services .icon i {
    font-size: 48px;
    line-height: 1;
    color: #fa6742;
    transition: 0.3s ease-in-out;
}
.featured-services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
}
.featured-services .title a {
    color: #111;
}
.featured-services .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
}
.cta {
    background: 0 0 / cover #4f6a7d;
    color: #fff;
}
.cta h3 {
    font-size: 28px;
    font-weight: 700;
}
.cta .cta-btn {
    font-weight: 500;
    font-size: 16px;
    display: inline-block;
    padding: 10px 35px;
    border-radius: 25px;
    transition: 0.5s;
    margin-top: 10px;
    border: 2px solid #fff;
    color: #fff;
}
.cta .cta-btn:hover {
    background: #fff;
    color: #4f6a7d;
}
.about .content h3 {
    font-weight: 600;
    font-size: 26px;
}
.about .content ul li {
    padding-bottom: 10px;
}
.about .content ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #4f6a7d;
}
.counts {
    background-image: url("./assets/Images/test/bg_layout_3.png");
    background-position: bottom center !important;
    background-repeat: no-repeat !important;
    color: #fff;
    z-index: 10000;
}
.counts .card {
    margin-top: 60px;
    background: rgba(255, 255, 255, 0.9);
    color: #000;
    z-index: 100000000000000;
}
.counts .card h2 span {
    color: #4f6a7d;
    font-size: 2em;
    letter-spacing: 3px;
}
.counts .card h3 {
    margin-top: 5%;
    color: #fa6742;
    font-size: 1.6em;
}
.counts .card p {
    font-size: 1.1em;
}
.counts .card .counts-img {
    padding: 5%;
}
.counts .count-box {
    background-color: #4f6a7d;
    padding: 30px;
    width: 100%;
    border: 1px solid #fff;
}
.counts .count-box i {
    display: block;
    font-size: 30px;
    color: #fff;
    float: left;
}
.counts .count-box span {
    font-size: 42px;
    line-height: 24px;
    display: block;
    font-weight: 700;
    color: #fff;
    margin-left: 50px;
}
.counts .count-box p {
    padding: 30px 0 0;
    margin: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
}
.counts .count-box a {
    font-weight: 600;
    display: block;
    margin-top: 20px;
    color: #7b7b7b;
    font-size: 15px;
    font-family: Poppins, sans-serif;
    transition: 0.3s ease-in-out;
}
.features .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 10px 60px;
}
.features .icon-box i {
    font-size: 48px;
    float: left;
    color: #4f6a7d;
}
.features .icon-box p {
    font-size: 15px;
    color: #848484;
    margin-left: 60px;
}
.features .image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
}
.services .icon-box {
    margin-bottom: 20px;
    text-align: center;
}
.services .icon {
    display: flex;
    justify-content: center;
}
.services .icon i {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 50%;
    transition: 0.5s;
    color: #4f6a7d;
    font-size: 40px;
    overflow: hidden;
    padding-top: 20px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
}
.services .icon-box:hover .icon i {
    box-shadow: 0 0 25px rgba(63, 187, 192, 0.3);
}
.services .title {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 18px;
    position: relative;
    padding-bottom: 15px;
}
.services .title a {
    color: #444;
    transition: 0.3s;
}
.services .title::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 2px;
    background: #4f6a7d;
    bottom: 0;
    left: calc(50% - 25px);
}
.services .description {
    line-height: 24px;
    font-size: 14px;
}
.appointment .php-email-form,
.nav {
    width: 100%;
}
.appointment .php-email-form .form-group {
    padding-bottom: 8px;
}
.appointment .php-email-form .validate,
.contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px;
    font-weight: 400;
    font-size: 13px;
}
.appointment .php-email-form .error-message,
.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #fa6742;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}
.appointment .php-email-form .error-message br + br,
.contact .php-email-form .error-message br + br {
    margin-top: 25px;
}
.appointment .php-email-form .sent-message,
.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}
.appointment .php-email-form .loading,
.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}
.appointment .php-email-form .loading:before,
.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: 1s linear infinite animate-loading;
    animation: 1s linear infinite animate-loading;
}
.appointment .php-email-form input,
.appointment .php-email-form select,
.appointment .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    padding: 10px !important;
}
.appointment .php-email-form input:focus,
.appointment .php-email-form select:focus,
.appointment .php-email-form textarea:focus {
    border-color: #4f6a7d;
}
.appointment .php-email-form input,
.appointment .php-email-form select {
    height: 44px;
}
.appointment .php-email-form textarea {
    padding: 10px 12px;
}
.appointment .php-email-form button[type="submit"] {
    background: #4f6a7d;
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
}
.appointment .php-email-form button[type="submit"]:hover {
    background: #52c2c6;
}
.departments {
    background: #588eb0;
    background: linear-gradient(63deg, #588eb0 0, #afbabe 0, #fff 100%);
}
.departments h3 a {
    color: #4f6a7d;
    font-weight: 700;
    font-size: 1em;
}
.departments h5 {
    font-size: 0.9em;
    color: #4f6a7d;
    font-weight: 700;
}
.departments p {
    font-size: 0.9em;
}
.departments .btn {
    background-color: #4f6a7d;
    color: #fff;
    transition: 0.4s ease-in-out;
}
.departments .btn:hover {
    background-color: #fff;
    color: #fa6742;
    border: 1px solid #fa6742;
}
.departments .nav-tabs {
    border: 0;
}
.departments .nav-link {
    border: 0;
    padding: 20px;
    color: #555;
    border-radius: 0;
    border-left: 5px solid #fff;
}
.departments .nav-link h4 {
    font-size: 18px;
    font-weight: 600;
    transition: 0.3s;
}
.departments .nav-link p {
    font-size: 14px;
    margin-bottom: 0;
}
.departments .nav-link.active {
    background: #f7fcfc;
    border-color: #4f6a7d;
}
.departments .tab-pane.active {
    -webkit-animation: 0.5s ease-out slide-down;
    animation: 0.5s ease-out slide-down;
}
.departments .tab-pane img {
    float: left;
    max-width: 300px;
    padding: 0 15px 15px 0;
}
@media (max-width: 768px) {
    .appointment-btn {
        margin: 0 48px 0 0;
        padding: 6px 18px;
    }
    #hero h2 {
        font-size: 28px;
    }
    .breadcrumbs .d-flex {
        display: block !important;
    }
    .breadcrumbs ol {
        display: block;
    }
    .breadcrumbs ol li {
        display: inline-block;
    }
    .departments .tab-pane img {
        float: none;
        padding: 0 0 15px;
        max-width: 100%;
    }
}
.departments .tab-pane h3 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #4f6a7d;
}
.departments .tab-pane p {
    color: #777;
}
@-webkit-keyframes slide-down {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes slide-down {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.testimonials .testimonial-item {
    box-sizing: content-box;
    min-height: 320px;
}
.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    margin: -40px 0 0 40px;
    position: relative;
    z-index: 2;
    border: 6px solid #fff;
}
.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0 5px 45px;
    color: #111;
}
.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0 0 0 45px;
}
.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: #b2e4e6;
    font-size: 26px;
}
.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}
.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}
.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 15px;
    padding: 20px 20px 60px;
    background: #f0fafa;
    position: relative;
    border-radius: 6px;
    z-index: 1;
}
.testimonials .owl-dots,
.testimonials .owl-nav {
    margin-top: 5px;
    text-align: center;
}
.testimonials .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd !important;
}
.gallery .owl-dot.active,
.testimonials .owl-dot.active {
    background-color: #4f6a7d !important;
}
@media (max-width: 767px) {
    .testimonials {
        margin: 30px 10px;
    }
}
.doctors .member {
    margin-bottom: 20px;
    overflow: hidden;
    text-align: center;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 15px rgba(63, 187, 192, 0.1);
}
.doctors .member .member-img {
    position: relative;
    overflow: hidden;
}
.doctors .member .social {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40px;
    opacity: 0;
    transition: 0.3s ease-in-out;
    text-align: center;
    background: rgba(255, 255, 255, 0.85);
}
.doctors .member .social a {
    transition: color 0.3s;
    color: #555;
    margin: 0 10px;
    padding-top: 8px;
    display: inline-block;
}
.doctors .member .social i {
    font-size: 18px;
    margin: 0 2px;
}
.doctors .member .member-info {
    padding: 25px 15px;
}
.doctors .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
    color: #555;
}
.doctors .member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #aaa;
}
.doctors .member .member-info p {
    font-style: italic;
    font-size: 14px;
    line-height: 26px;
    color: #777;
}
.doctors .member:hover .social {
    opacity: 1;
}
.gallery .owl-dots,
.gallery .owl-nav {
    margin-top: 25px;
    text-align: center;
}
.gallery .owl-item {
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
}
.gallery .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #e0f4f5 !important;
}
.gallery .gallery-carousel .owl-stage-outer {
    overflow: visible;
}
.gallery .gallery-carousel .center {
    border: 6px solid #4f6a7d;
    margin: -10px;
    box-sizing: content-box;
    padding: 4px;
    background: #fff;
    z-index: 1;
}
.pricing .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}
.pricing .btn-wrap,
.pricing h3 {
    padding: 20px 15px;
    background: #f8f8f8;
}
.pricing h3 {
    margin: -20px -20px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #777;
}
.pricing h4 {
    font-size: 36px;
    color: #4f6a7d;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    margin-bottom: 20px;
}
.pricing h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
}
.pricing h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
}
.pricing ul {
    padding: 0;
    list-style: none;
    color: #444;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
}
.pricing ul li {
    padding-bottom: 16px;
}
.pricing ul i {
    color: #4f6a7d;
    font-size: 18px;
    padding-right: 4px;
}
.pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
}
.pricing .btn-wrap {
    margin: 20px -20px -20px;
    text-align: center;
}
.pricing .btn-buy {
    background: #4f6a7d;
    display: inline-block;
    padding: 8px 35px 10px;
    border-radius: 4px;
    color: #fff;
    transition: 0.3s;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-weight: 600;
}
#more,
#more1,
#more11,
#more12,
#more2,
#more3,
#more4,
#more5,
#more6,
#more7,
#more8,
#more9,
.overlay {
    display: none;
}
.pricing .featured h3 {
    color: #fff;
    background: #4f6a7d;
}
.pricing .advanced {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px;
    background: #4f6a7d;
    color: #fff;
}
.faq .faq-list {
    padding: 0;
    list-style: none;
}
.faq .faq-list li {
    padding: 0 0 20px 25px;
}
.faq .faq-list a {
    display: block;
    position: relative;
    font-size: 18px;
    font-weight: 500;
}
.faq .faq-list i {
    font-size: 18px;
    position: absolute;
    left: -25px;
    top: 6px;
}
.faq .faq-list p {
    margin-bottom: 20px;
    font-size: 15px;
}
.faq .faq-list a.collapsed {
    color: #343a40;
}
.faq .faq-list a.collapsed i::before {
    content: "\eab2" !important;
}
.contact .info-box {
    color: #444;
    text-align: center;
    box-shadow: 0 0 20px rgba(214, 215, 216, 0.5);
    padding: 20px 0 30px;
}
.contact .info-box i {
    font-size: 32px;
    color: #4f6a7d;
    border-radius: 50%;
    padding: 8px;
    border: 2px dotted #c5ebec;
}
.contact .info-box h3 {
    font-size: 20px;
    color: #777;
    font-weight: 700;
    margin: 10px 0;
}
.contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}
.contact .php-email-form {
    box-shadow: 0 0 20px rgba(214, 215, 216, 0.5);
    padding: 30px;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 4px;
    box-shadow: none;
    font-size: 14px;
}
.contact .php-email-form input::focus,
.contact .php-email-form textarea::focus {
    background-color: #4f6a7d;
}
.contact .php-email-form input {
    padding: 20px 15px;
}
.contact .php-email-form textarea {
    padding: 12px 15px;
}
.contact .php-email-form button[type="submit"] {
    background: #4f6a7d;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}
@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes animate-loading {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
#footer {
    background: #eee;
    padding: 0;
    color: #fff;
    font-size: 14px;
}
#footer .footer-top {
    background: #4f6a7d;
    padding: 60px 0 30px;
}
#footer .footer-top .footer-info,
#footer .footer-top .footer-links {
    margin-bottom: 30px;
}
#footer .footer-top .footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px;
    padding: 2px 0;
    line-height: 1;
    font-weight: 700;
}
#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: Roboto, sans-serif;
}
#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #4f6a7d;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
    background: #fa6742;
    text-decoration: none;
}
#footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    position: relative;
    padding-bottom: 12px;
}
#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #fff;
    font-size: 18px;
    line-height: 1;
}
#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}
#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}
#footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
}
#footer .footer-top .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
}
#footer .footer-top .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    border: 0;
    font-size: 16px;
    padding: 0 20px;
    background: #4f6a7d;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
}
#footer .copyright {
    text-align: center;
    padding-top: 30px;
}
#footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
}
.categories-btn {
    background-color: #4f6a7d;
    color: #fff;
    border: 1px solid #fff;
}
.categories-btn:hover {
    background-color: #fff;
    color: #4f6a7d;
    border: 1px solid #4f6a7d;
}
.style-card-header {
    position: inherit;
}
.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000000;
    top: 0;
    left: 0;
    background-color: rgba(43, 62, 75, 0.99);
    transition: 0.5s;
}
.overlay-content {
    position: relative;
    top: 35%;
    width: 80%;
    text-align: center;
    margin: auto;
}
.license,
.related-reports {
    margin-left: 5%;
    margin-right: 5%;
}
.overlay-content h3 {
    font-size: 3em;
    color: #fff;
}
.overlay button,
.overlay input[type="text"] {
    float: left;
    padding: 15px;
    background: 0 0;
    font-size: 40px;
    border: none;
}
.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    cursor: pointer;
    color: #fff;
}
.overlay .closebtn:hover {
    color: #ccc;
}
.overlay input[type="text"] {
    width: 80%;
    border-bottom: 1px solid #fff;
}
.overlay input[type="text"]::-webkit-input-placeholder {
    color: #fff;
}
.overlay input[type="text"]:focus {
    color: #fff;
    border: 0;
}
.overlay button {
    width: 20%;
    cursor: pointer;
    color: #fff;
    border-bottom: 1px solid #fff;
}
.openBtn {
    background: rgba(255, 255, 255, 0);
    border: none;
    font-size: 15px;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 800;
}
.openBtn:hover {
    transform: scale(1.5);
}
.categories-reports {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.75);
    margin-bottom: 1em;
}
.categories-reports h3 {
    font-size: 1.4em;
}
.categories-reports ul {
    margin: 0;
    padding: 0;
}
.categories-reports ul li {
    display: inline;
    font-weight: 800;
    font-size: 0.8em;
}
#categories-reports-title a {
    font-weight: 650;
    transition: 0.25s ease-in-out;
}
#shorten-para {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1;
    max-height: 1 * N;
}
#categories-img img {
    width: 100%;
    max-height: 200px;
}
.categories-indutries {
    background: #f5f0f0;
}
.categories-indutries a {
    color: #222;
    transition: 0.5s ease-in-out;
}
.categories-indutries ul {
    padding: 5%;
    list-style-type: none;
}
.categories-indutries ul li {
    margin-bottom: 10px;
    border-bottom: 1px dashed #bbb;
}
.contact-card {
    margin-top: 5%;
}
.custom_btn_buy {
    width: 100%;
    padding: 2%;
    font-weight: 600;
    background: #4f6a7d;
    color: #fff;
    border: 1px solid #4f6a7d;
    transition: 0.5s ease-in-out;
}
.custom_btn_request,
.custom_btn_request:hover,
.request-btn,
.request-btn:hover {
    width: 100%;
    padding: 2%;
    font-weight: 600;
    border: 1px solid #fa6742;
    transition: 0.5s ease-in-out;
}
.custom_btn_request,
.request-btn {
    background: #fa6742;
    color: #fff;
}
.custom_btn_request:hover,
.request-btn:hover {
    background: #fff;
    color: #fa6742;
}
@keyframes blink {
    50% {
        background: #fa6742;
    }
}
@-webkit-keyframes blink {
    50% {
        opacity: 0;
    }
}
#contact-tab {
    animation: 2s step-start infinite blink;
    -webkit-animation: 2s step-start infinite blink;
}
.report-header h2 {
    font-size: 1.8em;
    font-weight: 700;
    color: #4f6a7d;
}
.report-header .table td,
.table th {
    padding: 0;
    font-size: 0.9em;
    color: #4f6a7d;
}
.nav-item {
    width: 33%;
    text-align: center;
    background: #4f6a7d;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background: #0e96ce;
}
#myTabContent {
    border: 1px solid #dee2e6;
    width: 99%;
    padding: 5%;
    border-top: none;
    color: #333;
}
.custom_btn_buy:hover {
    color: #4f6a7d;
    border: 1px solid #4f6a7d;
    background: #fff;
}
.style-card-header {
    position: inherit;
    font-weight: 700;
    background: #fa6742;
    color: #fff;
    text-align: center;
    position: inherit;
}
.list-group-item input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.license {
    width: 90%;
}
.license .card-body {
    padding: 5%;
    margin: 0;
}
.license ul {
    padding: 0;
    list-style-type: none;
}
.license ul li {
    border: none;
    font-weight: 600;
    font-size: 95%;
}
.checkmark {
    position: absolute;
    bottom: 15px;
    left: 4px;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
}
.list-group-item:hover input ~ .checkmark {
    background-color: #ccc;
}
.list-group-item input:checked ~ .checkmark {
    background-color: #2196f3;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.list-group-item input:checked ~ .checkmark:after {
    display: block;
}
.list-group-item .checkmark:after {
    top: 7px;
    left: 7px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #fff;
}
.related-reports {
    width: 90%;
    margin-top: 5%;
}
#related-link {
    color: #4f6a7d;
    transition: 0.5s ease-in-out;
}
#related-link:hover {
    color: #7b7b7b;
}
.report-contact {
    width: 99%;
    margin: 5% 0 0;
}
.report-contact .request-card {
    border-radius: none;
    text-align: center;
    background-color: #fa6742;
    font-weight: 700;
    padding: 5%;
    color: #fff;
    border: 1px solid #fff;
}
.report-contact .buy-now-card {
    border-radius: none;
    text-align: center;
    background-color: #4f6a7d;
    padding: 5%;
    color: #fff;
    border: 1px solid #fff;
}
.buy-now-card h4,
.request-card h4 {
    font-style: italic;
}
.buy-now-card .btn,
.request-card .btn {
    background: rgba(236, 248, 249, 0);
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    transition: 0.5s ease-in-out;
}
.request-card .btn:hover {
    background: #fff;
    border: 1px solid #fa6742;
    color: #fa6742;
    border-radius: 10px;
}
.buy-now-card .btn:hover {
    background: #fff;
    border: 1px solid #0087ad;
    color: #0087ad;
    border-radius: 10px;
}
.nav-link {
    color: #fff;
}
.nav-link:hover {
    color: #000;
}
.request-privacy {
    font-size: 0.6em;
    color: #aaa;
    padding: 2%;
}
.summary-btn {
    width: 90%;
    margin-left: 1%;
    padding: 2%;
    font-weight: 600;
    background: #4f6a7d;
    color: #fff;
    border: 1px solid #4f6a7d;
    transition: 0.5s ease-in-out;
}
.disclaimer h6 {
    font-size: 1.2em;
}
.disclaimer ol {
    padding: 2%;
    font-size: 0.8em;
    color: #bbb;
    line-height: 1.6;
    text-align: justify;
}
.key-questions h5 {
    font-weight: 650;
}
.key-questions ul {
    padding: 2%;
    font-size: 1em;
    line-height: 2;
}
/******************** ## Client Logo ********************/
/*******************************************************/
.client-logo-item {
    text-align: center;
    margin: 0 10px 35px;
}

.client-logo-item.style-two a {
    opacity: 0.3;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.client-logo-item.style-two a:hover {
    opacity: 1;
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}

.client-logo-item.style-three {
    background: #fff;
    margin-left: 0;
    margin-right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 20px;
    /* height: calc(100% - 35px); */
    border: 1px solid #010d091a;
    box-shadow: 3px 3px 10px rgba(48, 48, 48, 0.1);
    max-width: 85%;
    padding: 12px;

}

.logo-white .client-logo-item img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

.logo-white .client-logo-item.style-three {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: rgba(255, 255, 255, 0.05);
    border: 1.2px solid rgba(255, 255, 255, 0.1);
    -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
}

.logo-white .client-logo-item.style-three:hover {
    background: #fa6742;
}

.client-logo-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.client-logo-wrap .client-logo-item {
    margin-left: 25px;
    margin-right: 25px;
    max-width: calc(50% - 50px);
}

/* Client Logo Area Five */
.client-logo-five-wrap {
    position: relative;
    z-index: 1;
}

.client-logo-five-wrap:after {
    content: '';
    z-index: -1;
    width: 400px;
    height: 400px;
    position: absolute;
    left: 50%;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /* background: url(../images/backgrounds/client-logo-five.png); */
    background-size: cover;
}

.client-logo-five-wrap .client-logo-item.style-three {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.client-logo-five-wrap .client-logo-item.style-three:not(:hover) {
    border-color: transparent;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.client-logo-five-content .learn-more {
    font-size: 18px;
    font-weight: 600;
    color: #fa6742;
    display: inline-block;
    text-decoration: underline;
}

.client-logo-five-content .learn-more i {
    margin-left: 5px;
    -webkit-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    transform: rotate(-30deg);
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
    background:#0E96CE;
}
.nav-keyword h3{
    font-size:20px;
    margin-top:'.$margin_top.'px;
    margin-left:20px;
    text-transform:uppercase;
}
#header.header-scrolled,
#header.header-pages {
  height: '.$nav_height.'px;
  padding: 15px 0;
  background-color: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.3);
}
/* Footer css */
.footers a {color:white;}
.footers p {color:white; line-height: 27px;}
.footers ul {line-height:30px;}
#social-fb:hover {
     color: #3B5998;
     transition:all .001s;
 }
 #social-tw:hover {
     color: #4099FF;
     transition:all .001s;
 }
 #social-gp:hover {
     color: #d34836;
     transition:all .001s;
 }
 #social-em:hover {
     color: #f39c12;
     transition:all .001s;
 }
 .footers, .disclaimer{
  background: #4f6a7d;
 
 }
.footers{
     position: relative;
}
 .footers a {
    text-decoration: none;
 }
 
 .footers-three{
    padding: 13px 0px;
 }
 
 .footers-two h5, .footers-five h5{
    font-size: 25px;
 }
.social-menu ul {
  
    padding: 0;
    display: flex;
}
.social-menu ul li {
    list-style: none;
    margin: 0 10px;
}
.social-menu ul li .fa {
    color: #000000;
    font-size: 25px;
    line-height: 50px;
    transition: .5s;
}
.social-menu ul li .fa:hover {
    color: #ffffff;
}
.social-menu ul li a {
    position: relative;
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    transition: 0.5s;
    transform: translate(0,0px);
    box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.5);
}
.social-menu ul li a:hover {
    transform: rotate(0deg) skew(0deg) translate(0, -10px);
}
.social-menu ul li:nth-child(1) a:hover {
    background-color: #3b5999;
}
.social-menu ul li:nth-child(2) a:hover {
    background-color: #55acee;
}
.social-menu ul li:nth-child(3) a:hover {
    background-color: #e4405f;
}
.social-menu ul li:nth-child(4) a:hover {
    background-color: #cd201f;
}
.social-menu ul li:nth-child(5) a:hover {
    background-color: #0077B5;
}
.social-menu span{
    color:white;
    display: block;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}
.social-menu i{
    color: #000000cc;
    font-size: 23px;
    margin: 10px;
}
.social-menu i:hover {
    color: white;
    text-decoration: none; /* Optional: removes underline on hover */
}
.footer-widget{
    text-align: left;
  }
  .footer-widget-heading h3 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  }
  .footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #FA6742;
  }
  .footer-widget ul li {
  /* display: inline-block;
  float: left; */
  /* width: 50%; */
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 0px;      
     
  }
  .footer-widget ul li a:hover{
  color: white;
  }
  .footer-widget ul li a {
  color: var(--ayur-primary-light);
  text-transform: capitalize;
  }
  .subscribe-form {
  position: relative;
  overflow: hidden;
  }
  .subscribe-form input {
  width: 95%;
  padding: 13px 10px;
  
  border: 1px solid #ffffff;
  color: #FA6742;
  }
  .subscribe-form button {
    position: absolute;
    right: 0;
    background: #FA6742;
    padding: 13px 46px;
    border: 1px solid #FA6742;
    top: 0;
  }
  .subscribe-form button i {
  color: #ffffff;
  font-size: 22px;
  transform: rotate(-6deg);
  }
 .form-container {
    width: 300px;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    overflow: hidden;
}
.form-header {
    background-color: #0e85c0;
    color: #fff;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
.form-body {
    padding: 20px;
}
.form-body label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
}
.form-body input[type="text"],
.form-body input[type="email"],
.form-body input[type="tel"],
.form-body input[type="file"],
.form-body input[type="submit"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
}
.form-body .captcha {
    font-size: 24px;
    color: #333;
    text-align: center;
    background-color: #f0f0f0;
    padding: 8px;
    margin-bottom: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}
.form-body input[type="submit"] {
    background-color: #0e85c0;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    cursor: pointer;
}
.form-body input[type="submit"]:hover {
    background-color: #1b365d;
}
 
.custom-table {
    width: 100%;
    border-collapse: collapse;
}
 
.custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
}
 
.custom-table tr:nth-child(even) {
    background-color: #f9f9f9;
}
 
.custom-table tr:hover {
    background-color: #f1f1f1;
}
 
.custom-table td:first-child, .custom-table td:nth-child(3) {
    font-weight: bold;
    color: #0e85c0; /* Update color as per your theme */
}
 
.job-card {
    width: 400px;
    height: 200px;
    margin: 50px auto;
    border-radius: 10px;
    border: none;
  }
 
  .badge-circle {
    width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      margin: 19px 8px;
  }
 
  .card-title1 {
    font-size: 22px;
    font-weight: bold;
  }
 
  .card-text i {
    margin-right: 5px;
  }
 
  .btn-link {
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
  }
 
  .btn-link:hover {
    text-decoration: underline;
  }
  .btn1{
    background-color: #FA6742;
      padding: 11px 20px;
      border-radius: 10px;
      color: white;
  }
  .animated-title {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }
 
  .animated-title:hover {
    transform: scale(1.2); /* Zoom in on hover */
  }
  .shadow-sm:hover {
    transform: scale(1.05); /* Grows the card slightly */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adds a shadow effect */
    transition: all 0.3s ease-in-out; /* Smooth transition */
  }
 
  .btn1:hover  {
    color: white; /* Changes button text color on hover */
    text-decoration: underline; /* Adds underline to the link */
  }
 
  .card-title1:hover {
    color: #1b365d; /* Changes the title color on hover */
  }
 
/* Testimonial section css Start */
.ayur-testimonial-sec {
    /* background-image: url('../images/testimonialbanner.png'); */
    max-width: 1820px;
    margin: 0 auto;
    padding: 100px 0 80px;
}

.ayur-test-head {
    margin: 0 0 24px;
}

.swiper.ayur-testimonial-slider {
    padding: 20px 0;
}

.ayur-test-box {
    max-width: 850px;
    padding: 5px;
    box-shadow: 3px 4px 29.6px 0px #0000000F;
    background-color: #fff;
    border-radius: 10px;
    transition: all .3s linear;
    margin: 0 10px;
}

.ayur-test-text {
    max-width: 840px;
    padding: 20px 24px;
    border-radius: 5px;
    background-color: #00000014;
}

.ayur-test-namesec {
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    padding: 26px 25px 20px;
}

.ayur-testname {
    /* display: flex; */
    align-items: center;
    gap: 14px;
}

.ayur-testname h3 {
    font-size: 18px;
    font-weight: 600;
    color: #222222;
}

.ayur-testimonial-section .swiper-button-prev {
    left: 16%;
    top: 60%;
}

.ayur-testimonial-section .swiper-button-next {
    right: 16%;
    top: 60%;
}

.ayur-testimonial-section .swiper-button-prev svg path,
.ayur-testimonial-section .swiper-button-next svg path {
    fill: #797979;
    transition: all .3s linear;
}

.ayur-testimonial-section .swiper-button-prev:hover svg path,
.ayur-testimonial-section .swiper-button-next:hover svg path {
    fill: #CD8973;
}

.ayur-test-box .ayur-test-namesec .ayur-testquote svg path {
    transition: all .3s linear;
}

.ayur-test-box:hover .ayur-test-namesec .ayur-testquote svg path {
    fill: #CD8973;
    opacity: 1;
}
.ayur-bgcover{
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px 0 80px;
  }
 
  .swiper-button-next:after, .swiper-button-prev:after{
    display: none;
  }
 
  .ayur-testimonial-sec{
    max-width: 1820px;
    margin: 0 auto;
    padding: 15px 0;
  }
  .ayur-test-head{
    margin: 0 0 24px;
  }
  .swiper.ayur-testimonial-slider {
    padding: 20px 0;
  }


  .speak-profile-card {
    position: sticky !important;
    top: 290px;
    background-color: #f8f9fa;
    border: none;
    border-radius: 10px;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .profile-avatar img {
    width: 100%;
    height: 100%;
  }
  
  .speak-card-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0 !important;
    background-color: #4F6A7D;
    color: #ffffff;
    padding: 6px;
    border-radius: 5px;
  }
  
  .speak-card-text {
    font-size: 13px;
    margin-bottom: 5px;
  }
  .social-icons a {
    color: #4F6A7D;
    margin: 0 7px;
    font-size: 14px;
    transition: color 0.3s ease;
  
  }
  .social-icons a:hover {
    color: #FA6742;
  }
  .vertical-line {
    border-right: 1px solid #4A6572;
    height: 100px;
    margin: 0 auto;
}
.web-pagination {
    position: relative;
    float: left;
    width: 100%;
    box-sizing: border-box;
    /* padding: 10px; */
  }
   
  ul.pagination {
    position: relative;
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0px;
    list-style-type: none;
    padding: 6px 10px;
    /* border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f1f1f1; */
  }
   
  ul.pagination li {
    position: relative;
    float: left;
    width: auto;
    box-sizing: border-box;
    padding: 5px;
  }
   
  ul.pagination li a {
    position: relative;
    float: left;
    width: auto;
    box-sizing: border-box;
    padding: 5px 10px;
    border: 1px solid #ccc;
    color: gray;
    font-family: 'Helvetica' !important;
    border-radius: 5px;
    background: white;
  }
   
  ul.pagination li a.active {
    background-color: #FA6742;
    color: white;
    font-weight: bold;
    border: 1px solid #FA6742;
  }
   
  ul.pagination li a:hover {
    background-color: #FA6742;
    color: white;
    font-weight: bold;
    border: 1px solid #FA6742;
  }
   
  ul.pagination li a.total_pages {
    border: none;
    background: none;
    color: #333;
    font-weight: bold;
  }
   
  .total_page_head {
    float: left;
  }
   
  .total_page_numbers {
    float: right;
  }

  /* new Services page */
  .btn-highlighted{
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.1em;
  text-align: center;
  text-transform: capitalize;
  background: var(--accent-color);
  color: #FA6742;
  border-radius: 100px;
  padding: 19px 25px;
  border: none;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  z-index: 1;
}
 
.btn-highlighted:hover{
  background-color: transparent;
  color: #fff;
}
 
.btn-highlighted::before{
  content: '';
    display: block;
    position: absolute;
  top: 0;
    left: auto;
  right: 0;
    bottom: 0;
  width: 0;
  height: 100%;
  border-radius: 99px;
    background: #FA6742;
    transition: all 0.3s ease-in-out;
  z-index: -1;
}
 
.btn-highlighted:hover::before{
  width: 100%;
  left: 0;
  right: auto;
}
 
.section-row{
  margin-bottom: 40px;
}
 
.section-row .section-title{
  margin-bottom: 0;
  margin-right: 20px;
}
 
.section-btn{
  text-align: end;
}
 

.our-expertise.bg-section{
  background-color: #b5b5b5;
  overflow: hidden;
  padding: 40px 0;
}
 
.our-tab-nav{
  margin-bottom: 20px;
  text-align: center;
}
 
.our-tab-nav .nav-tabs{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  border: none;
  list-style: none;
  padding: 0;
  margin: 0;
}
 
 
 
.our-tab-nav ul li .nav-link{
  width: 100%;
  background-color: #ffffff;
  border: none;
  border-radius: 100px;
  color: black;
  font-size: 14px;
  font-weight: 600;
}
 
.our-tab-nav ul li .nav-link:hover{
  border: none;
}
 
.our-tab-nav ul li .nav-link.btn-highlighted:before{
  background-color: #FA6742;
  color: #ffffff;
}
 
.our-tab-nav ul li .nav-link.active{
  background-color: #FA6742;
  color: #ffffff;
  border: none;
}
 
.expertise-content{
  background-color: #ffffff;
  border-radius: 30px;
  padding: 40px;
  margin: 0 15px;
}
 
.expertise-content-header{
  margin-bottom: 40px;
}
 
.expertise-content-header h3{
  font-size: 26px;
  text-transform: capitalize;
  margin-bottom: 20px;
}
 
.expertise-content-header p{
  margin: 0;
  font-size: 14px;
    line-height: 29px;
    text-align: justify;
}
.expertise-content-header li{
  font-size: 14px;
}
.expertise-content-body{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}
 
.expertise-list-item{
  width: 100%;
  display: flex;
  align-items: center;
}
 
.expertise-list-item .icon-box{
  margin-right: 20px;
}
 
.expertise-list-item .icon-box img{
  max-width: 24px;
}
 
.expertise-list-content{
  width: calc(100% - 44px);
}
 
.expertise-list-content p{
  color: #FA6742;
  text-transform: capitalize;
  margin: 0;
}
 
.expertise-image figure{
  display: block;
  border-radius: 30px;
  overflow: hidden;
}
 
.expertise-image img{
  width: 100%;
  aspect-ratio:  1 / 0.75;
  object-fit: cover;
  border-radius: 30px;
}
/* Service */
.iconic-box {
    z-index: 1;
    overflow: hidden;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: #ffffff;
    position: relative;
    margin-bottom: 30px;
    border-radius: 15px;
    padding: 40px 50px 35px;
}

@media only screen and (max-width: 375px) {
    .iconic-box {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.iconic-box .icon {
    z-index: 1;
    line-height: 1;
    font-size: 65px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    position: relative;
    margin-bottom: 45px;
    color: #FA6742;
}

.iconic-box .icon:after {
    content: '';
    width: 80px;
    height: 80px;
    z-index: -1;
    position: absolute;
    left: 5px;
    top: 10px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    border-radius: 50%;
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
    background: #ffffff;
}

.iconic-box .content h4 {
    margin-bottom: 15px;
}

.iconic-box .content p {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    font-size: 18px;
    font-weight: 500;
}

.iconic-box .content hr {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin-top: 30px;
    margin-bottom: 20px;
}

.iconic-box .content .read-more {
    margin-top: 8px;
}

.iconic-box .bg {
    z-index: -1;
    position: absolute;
    right: -20px;
    top: -20px;
    max-width: 60%;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.iconic-box:hover {
    background: #FA6742;
}

.iconic-box:hover .icon,
.iconic-box:hover .read-more,
.iconic-box:hover .content p,
.iconic-box:hover .content h4 a {
    color: #ffffff;
}

.iconic-box:hover .icon:after {
    background: rgba(255, 255, 255, 0.1);
}

.iconic-box:hover hr {
    opacity: 0.15;
    background: #ffffff;
}

.iconic-box:hover .bg {
    top: 0;
    right: 0;
}

.iconic-box.style-three {
    padding: 30px 40px 18px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 375px) {
    .iconic-box.style-three {
        padding-left: 33px;
        padding-right: 33px;
    }
}

.iconic-box.style-three .icon {
    margin-bottom: 20px;
}

.iconic-box.style-three .icon:after {
    display: none;
}

.iconic-box.style-three h5 {
    margin-bottom: 18px;
    letter-spacing: -0.66px;
}

.iconic-box.style-three h5 a {
    color: #ffffff;
}

.iconic-box.style-three p {
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.55);
}

.iconic-box.style-three .bg {
    top: 25px;
    right: 30px;
}

.iconic-box.style-three:hover p {
    color: rgba(255, 255, 255, 0.55);
}

.iconic-box.style-three.text-black {
    background: #ffffff;
    border: 1.5px solid #FEC458;
}

.iconic-box.style-three.text-black .content h5 a {
    color: #001726;
}

.iconic-box.style-three.text-black .content p {
    color: #b5b5b5;
}

.iconic-box.style-three.text-black:hover {
    border-color: #FF1B8D;
}

.iconic-box.style-four {
    background: #ffffff;
    text-align: center;
    border-radius: 20px;
    padding: 38px 28px 15px;
    border: 3px solid #ffffff;
    -webkit-box-shadow: 0px 10px 60px rgba(155, 155, 155, 0.1);
    box-shadow: 0px 10px 60px rgba(155, 155, 155, 0.1);
}

.iconic-box.style-four .icon {
    width: 70px;
    height: 70px;
    font-size: 27px;
    line-height: 70px;
    text-align: center;
    margin: 0 auto 30px;
    color: #FA6742;
}

.iconic-box.style-four .icon:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: #FA6742;
}

.iconic-box.style-four h5 {
    font-size: 20px;
    margin-bottom: 12px;
}

.iconic-box.style-four p {
    font-size: 16px;
    font-weight: 400;
}

.iconic-box.style-four:hover {
    border-color: #FA6742;
}

.iconic-box.style-four:hover .icon {
    color: #ffffff;
}

.iconic-box.style-four:hover .icon:after {
    opacity: 1;
}

.iconic-box.style-four:hover p {
    color: #b5b5b5;
}

.iconic-box.style-five {
    background: transparent;
    padding: 30px 50px 15px;
    border: 1px solid #FEC458;
}

@media only screen and (max-width: 375px) {
    .iconic-box.style-five {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.iconic-box.style-five .icon {
    font-size: 55px;
    margin-bottom: 10px;
    color: #FA6742;
}

.iconic-box.style-five .icon:after {
    display: none;
}

.iconic-box.style-five p {
    font-size: 16px;
    font-weight: 400;
}

.iconic-box.style-five:hover {
    background: #ffffff;
    border-color: #ffffff;
    -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
}

.iconic-box.style-five:hover p {
    color: #b5b5b5;
}

.iconic-box.style-six {
    background: #F9F9FF;
    padding: 40px 35px 20px;
    border: 1px solid #FEC458;
}

@media only screen and (max-width: 375px) {
    .iconic-box.style-six {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.iconic-box.style-six .icon {
    font-size: 55px;
    margin-bottom: 25px;
    color: var(--secondary-color);
}

.iconic-box.style-six .icon:after {
    display: none;
}

.iconic-box.style-six h5 {
    font-size: 20px;
    margin-bottom: 15px;
    letter-spacing: -0.4px;
}

.iconic-box.style-six p {
    font-size: 16px;
    font-weight: 400;
}

.iconic-box.style-six:hover {
    background: #ffffff;
    border-color: #ffffff;
    -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
}

.iconic-box.style-six:hover h5 a {
    text-decoration: underline;
}

.iconic-box.style-six:hover p {
    color: #b5b5b5;
}

.iconic-box.style-seven {
    padding: 20px 30px 0px;
    background: transparent;
    height: 95%;
}

@media only screen and (max-width: 375px) {
    .iconic-box.style-seven {
        padding-left: 30px;
        padding-right: 30px;
    }
}
.iconic-box.style-seven:after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.05;
    background: #FA6742;
}
.iconic-box.style-seven .icon {
    width: 60px;
    height: 60px;
    color: #ffffff;
    font-size: 30px;
    line-height: 60px;
    text-align: center;
    border-radius: 12px;
    margin-bottom: 20px;
    background: #FA6742;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
}
.iconic-box.style-seven .icon:after {
    display: none;
}
.iconic-box.style-seven .icon-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 15px;
}
.iconic-box.style-seven .icon-title i {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    font-size: 20px;
    margin-right: 15px;
    width: 50px;
    height: 50px;
    background: #ffffff;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    color: #FA6742;
}
.iconic-box.style-seven .icon-title h5 {
    margin-bottom: 0;
}
.iconic-box.style-seven h5 a {
    font-size: 18px;
    margin-bottom: 10px;
    letter-spacing: -0.4px;
    font-weight: 700;
    color:black;
}
.iconic-box.style-seven p {
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
}
.iconic-box.style-seven .bg {
    top: auto;
    right: -20px;
    bottom: -20px;
}
.iconic-box.style-seven:hover {
    background: #FA6742;
}
.iconic-box.style-seven:hover .icon {
    background: #ffffff;
    color: #FA6742;
    text-align: center;
}
.iconic-box.style-seven:hover h5 a {
    color: #ffffff;
}
.iconic-box.style-seven:hover .bg {
    right: 0;
    bottom: 0;
}
.iconic-box.style-seven.border {
    padding-top: 30px;
    padding-bottom: 10px;
}

.sticky-card {
    position: sticky !important;
    top: 15px !important; /* Adjust this value as needed */
    z-index: 10 !important; /* Ensure it stays above other content */ 
}

